import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Yf-3_2SNzkc">
    <SEO title="When Enough Isn't Enough - Hebrews" />
  </Layout>
)

export default SermonPost
